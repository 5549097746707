var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "MiniCartItem",
      class: {
        "MiniCartItem--removing": _vm.isRemoving,
        "MiniCartItem--lastChild": _vm.isLastChild,
      },
    },
    [
      !_vm.isPresentInRossko
        ? _c("a", { attrs: { href: _vm.url } }, [
            _c(
              "picture",
              {
                staticClass: "MiniCartItem__picture",
                class: { "MiniCartItem__picture--withPlaceholder": !_vm.image },
              },
              [
                _vm.image
                  ? _c("img", {
                      staticClass: "MiniCartItem__image",
                      attrs: {
                        srcset: `${_vm.image1x}, ${_vm.image2x} 2x`,
                        src: _vm.image1x,
                        alt: _vm.name,
                      },
                    })
                  : _c("inline-svg", {
                      staticClass: "MiniCartItem__placeholder",
                      attrs: { src: "/assets/images/image-placeholder.svg" },
                    }),
              ],
              1
            ),
          ])
        : _c("span", [
            _c(
              "picture",
              {
                staticClass: "MiniCartItem__picture",
                class: { "MiniCartItem__picture--withPlaceholder": !_vm.image },
              },
              [
                _vm.image
                  ? _c("img", {
                      staticClass: "MiniCartItem__image",
                      attrs: {
                        srcset: `${_vm.image1x}, ${_vm.image2x} 2x`,
                        src: _vm.image1x,
                        alt: _vm.name,
                      },
                    })
                  : _c("inline-svg", {
                      staticClass: "MiniCartItem__placeholder",
                      attrs: { src: "/assets/images/image-placeholder.svg" },
                    }),
              ],
              1
            ),
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "MiniCartItem__info" }, [
        _c("div", { staticClass: "MiniCartItem__nameWrapper" }, [
          !_vm.isPresentInRossko
            ? _c(
                "a",
                {
                  staticClass: "MiniCartItem__nameLink",
                  attrs: { href: _vm.url },
                },
                [
                  _c("span", {
                    staticClass: "MiniCartItem__name",
                    domProps: { innerHTML: _vm._s(_vm.name) },
                  }),
                ]
              )
            : _c("span", { staticClass: "MiniCartItem__nameSpan" }, [
                _c("span", {
                  staticClass: "MiniCartItem__name",
                  domProps: { innerHTML: _vm._s(_vm.name) },
                }),
              ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "MiniCartItem__footer" }, [
          _c("div", { staticClass: "MiniCartItem__priceBlock" }, [
            _c("div", { staticClass: "MiniCartItem__priceValues" }, [
              _c("span", {
                staticClass: "MiniCartItem__price MiniCartItem__price--total",
                domProps: {
                  innerHTML: _vm._s(_vm.formatRoubles(_vm.totalPrice)),
                },
              }),
              _vm._v(" "),
              _vm.isUserWholesaler
                ? _c("span", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPricePerItemShown,
                        expression: "isPricePerItemShown",
                      },
                    ],
                    key: "price-per-item",
                    staticClass:
                      "MiniCartItem__price MiniCartItem__price--perUnit",
                    domProps: {
                      innerHTML: _vm._s(
                        `(${_vm.formatRoubles(_vm.pricePerItem)} / шт.)`
                      ),
                    },
                  })
                : _c("span", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPricePerPackageShown,
                        expression: "isPricePerPackageShown",
                      },
                    ],
                    key: "price-per-package",
                    staticClass:
                      "MiniCartItem__price MiniCartItem__price--perUnit",
                    domProps: {
                      innerHTML: _vm._s(
                        `(${_vm.formatRoubles(_vm.pricePerPackage)} / упак.)`
                      ),
                    },
                  }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "MiniCartItem__aboutBlock" }, [
            _c("span", {
              staticClass: "MiniCartItem__packageUnitsQuantity",
              domProps: {
                innerHTML: _vm._s(
                  `В&nbsp;упаковке: ${_vm.packageItemsCount}&nbsp;шт.`
                ),
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "MiniCartItem__stocks" },
              [
                _c("product-status", {
                  attrs: {
                    quantity: _vm.quantity,
                    "quantity-in-package": _vm.packageItemsCount,
                    stocks: _vm.stocks,
                    "is-user-wholesaler": _vm.isUserWholesaler,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "MiniCartItem__counter" },
            [
              _c("add-to-cart", {
                attrs: {
                  "product-id": _vm.productId,
                  quantity: _vm.quantity,
                  "quantity-in-package": _vm.packageItemsCount,
                  "is-user-wholesaler": _vm.isUserWholesaler,
                  mode: "cart",
                  "is-loading": _vm.isLoading,
                  "always-show-counter": true,
                },
                on: { "change-quantity": _vm.changeQuantity },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "Button Button--remove MiniCartItem__removeButton",
            attrs: { type: "button" },
            on: { click: _vm.removeItem },
          },
          [
            _c(
              "span",
              { staticClass: "Button__icon" },
              [_c("inline-svg", { attrs: { src: "/assets/icons/close.svg" } })],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }