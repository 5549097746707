var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "article",
    { staticClass: "ProductCompactCard ProductCompactCard--modeSlider" },
    [
      _c("span", { staticClass: "ProductCompactCard__head" }, [
        _c(
          "picture",
          {
            staticClass: "ProductCompactCard__picture",
            class: {
              "ProductCompactCard__picture--withPlaceholder": !_vm.image,
            },
          },
          [
            _vm.image
              ? _c("img", {
                  staticClass: "ProductCompactCard__image",
                  attrs: {
                    srcset: `${_vm.image1x}, ${_vm.image2x} 2x`,
                    src: _vm.image1x,
                    alt: _vm.name,
                  },
                })
              : _c("inline-svg", {
                  staticClass: "ProductCompactCard__placeholder",
                  attrs: { src: "/assets/images/image-placeholder.svg" },
                }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.name
          ? _c("span", {
              staticClass: "ProductCompactCard__title",
              domProps: { innerHTML: _vm._s(_vm.name) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.description
          ? _c("p", {
              staticClass: "ProductCompactCard__description",
              domProps: { innerHTML: _vm._s(_vm.description) },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ProductCompactCard__footer" }, [
        _vm.priceRetailPerPackage
          ? _c("span", {
              staticClass: "ProductCompactCard__price",
              domProps: {
                innerHTML: _vm._s(_vm.formatRoubles(_vm.priceRetailPerPackage)),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ProductCompactCard__availability" },
          [
            _c("product-status", {
              attrs: {
                quantity: _vm.quantity,
                "quantity-in-package": _vm.quantityInPackage,
                stocks: _vm.stocks,
                "is-user-wholesaler": _vm.isUserWholesaler,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ProductCompactCard__controls" }, [
        _c(
          "div",
          { staticClass: "ProductCompactCard__addToCart" },
          [
            _c("add-to-cart", {
              attrs: {
                "product-id": _vm.productId,
                quantity: _vm.quantity,
                mode: "catalog",
                "quantity-in-package": _vm.quantityInPackage,
                "is-user-wholesaler": _vm.isUserWholesaler,
                "is-loading": _vm.isLoading,
                disabled: !_vm.possibleToOrder,
              },
              on: { "change-quantity": _vm.changeCartProductQuantity },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "Button Button--favorite ProductCompactCard__favorite",
            class: {
              liked: _vm.isFavorite(_vm.productId),
              "Button--noEvents": _vm.isFavoriteLoading(_vm.productId),
            },
            attrs: { type: "button", title: "Добавить в избранное" },
            on: {
              click: function ($event) {
                return _vm.toggleFavorite(_vm.productId)
              },
            },
          },
          [
            _c(
              "span",
              { staticClass: "Button__icon" },
              [_c("inline-svg", { attrs: { src: "/assets/icons/star.svg" } })],
              1
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }