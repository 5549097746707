import ApiClient from '@library/scripts/api/client';

// GET

export function apiGetDeliveryOptions(payload) {
    return ApiClient.get('/api/orders/delivery_options', payload);
}

export function apiOrdersCalculate(payload) {
    return ApiClient.get('/api/orders/calculate', payload);
}

export function apiInvoiceStatus(payload) {
    return ApiClient.get('/api/orders/invoice/status', payload);
}

export function apiInvoiceRegenerate(payload) {
    return ApiClient.post('/api/orders/invoice/regenerate', payload);
}

export function apiOrdersCreate(payload) {
    return ApiClient.post('/api/orders/create', payload);
}

export function apiOrdersCancel(payload) {
    return ApiClient.post('/api/orders/cancel', payload);
}

export function apiChangeOrderPaymentMethod(payload) {
    return ApiClient.post('/api/orders/change_payment_method', payload);
}

export function apiGetStreets(payload) {
    return ApiClient.get('/api/locations/suggestions/streets', payload);
}

export function apiGetCities(payload) {
    return ApiClient.get('/api/locations/suggestions/cities', payload);
}

export function apiCartDetails() {
    return ApiClient.get('/api/cart/details', null, {
        'Cache-Control': 'No-Cache',
        Pragma: 'No-Cache',
    });
}

export function apiGetCountries(payload) {
    return ApiClient.get('/api/locations/suggestions/countries', payload);
}

export function apiGetMarkets(payload) {
    return ApiClient.get('/api/catalog/filters/markets', payload);
}

export function apiGetModels(payload) {
    return ApiClient.get('/api/catalog/filters/models', payload);
}

export function apiGetBodies(payload) {
    return ApiClient.get('/api/catalog/filters/bodies', payload);
}

export function apiGetYears(payload) {
    return ApiClient.get('/api/catalog/filters/production_years', payload);
}

export function apiSearchByVinOrFrame(payload) {
    return ApiClient.get('/api/catalog/search_by_vin_or_frame', payload);
}

export function apiGetCheckByVinOrFrame(payload) {
    return ApiClient.get('/api/catalog/find_vehicles', payload);
}

export function apiGetApplicableDetails(payload) {
    return ApiClient.get('/api/catalog/find_applicable_details', payload);
}

export function apiGetConcomitantProducts(payload) {
    return ApiClient.get('/api/catalog/concomitant_products', payload);
}

//shared cart
export function apiGetSharedCartLink() {
    return ApiClient.get('/api/cart/share', null);
}

export function apiGetAddSharedToCart(payload) {
    return ApiClient.get('/api/cart/add_shared_to_cart', payload);
}

//wheel of fortune
export function apiGetWheelOfFortuneSpin() {
    return ApiClient.get('/api/wheel-of-fortune/spin', null);
}

// POST

export function apiRequestCode(payload) {
    return ApiClient.post('/api/auth/request_code', payload);
}

export function apiRequestCodeEmail(payload) {
    return ApiClient.post('/api/auth/email/request_code', payload);
}

export function apiLoginByEmail(payload) {
    return ApiClient.post('/api/auth/cookie/login_email', payload);
}

export function apiLogin(payload) {
    return ApiClient.post('/api/auth/cookie/login', payload);
}

export function apiChangePhoneRequestCode(payload) {
    return ApiClient.post('/api/user/profile/change_phone/request_code', payload);
}

export function apiEmailLogin(payload) {
    return ApiClient.post('/api/auth/email/login', payload);
}

export function apiResetPasswordRequest(payload) {
    return ApiClient.post('/api/auth/password_reset/request', payload);
}

export function apiResetPasswordComplete(payload) {
    return ApiClient.post('/api/auth/password_reset/complete', payload);
}

export function apiEmailRegister(payload) {
    return ApiClient.post('/api/auth/email/register', payload);
}

export function apiChangePhone(payload) {
    return ApiClient.post('/api/user/profile/change_phone/complete', payload);
}

export function apiChangeEmailRequestConfirmation(payload) {
    return ApiClient.post('/api/user/profile/change_email/request_confirmation', payload);
}

export function apiUpdateProfile(payload) {
    return ApiClient.post('/api/user/profile/update', payload);
}

export function apiProfileUpdatePassword(payload) {
    return ApiClient.post('/api/user/profile/password_update', payload);
}

export function apiLogout() {
    return ApiClient.post('/api/auth/cookie/logout');
}

export function apiAddProduct(payload) {
    return ApiClient.post('/api/cart/add_product', payload);
}

export function apiAddMultipleProducts(payload) {
    return ApiClient.post('/api/cart/add_multiple_products', payload);
}

export function apiSetQuantity(payload) {
    return ApiClient.post('/api/cart/set_quantity', payload);
}

export function apiCitySelect(payload) {
    return ApiClient.post('/api/locations/select_city', payload);
}

export function apiFeedback(payload) {
    return ApiClient.post('/api/feedback', payload);
}

export function apiTradingPartnerRequest(payload) {
    return ApiClient.post('/api/trading_partner_request/submit', payload);
}

export function apiServiceStationRequest(payload) {
    return ApiClient.post('/api/service_station_request/submit', payload);
}

export function apiReclamation(payload) {
    return ApiClient.post('/api/reclamations', payload);
}

export function apiRegisterDetails(payload) {
    return ApiClient.post('/api/details/register', payload);
}

export function apiSubmitInstructionComment(payload) {
    return ApiClient.post('/api/instructions/comments/submit', payload);
}

export function apiFavoritesAdd(payload) {
    return ApiClient.post('/api/favorites/add', payload);
}

export function apiFavoritesRemove(payload) {
    return ApiClient.post('/api/favorites/remove', payload);
}

export function apiUpdateProfileSettings(payload) {
    return ApiClient.post('/api/user/profile/settings/update', payload);
}

export function apiCreateUserAddress(payload) {
    return ApiClient.post('/api/user/addresses/create', payload);
}

export function apiUpdateUserAddress(payload) {
    return ApiClient.post('/api/user/addresses/update', payload);
}

export function apiRemoveUserAddress(payload) {
    return ApiClient.post('/api/user/addresses/destroy', payload);
}

export function apiCartClear(payload) {
    return ApiClient.post('/api/cart/clear', payload);
}

//discount to cart
export function apiApplyDiscountToCart(payload) {
    return ApiClient.post('/api/cart/apply_coupon', payload);
}
export function apiReleaseDiscountToCart(payload) {
    return ApiClient.post('/api/cart/release_coupon', payload);
}


//industry supply
export function apiSendIndustrySupplyRequest(payload) {
    return ApiClient.get('/api/industrial-supply/send', payload);
}

//search by size
export function apiProductSearchBySize(payload) {
    return ApiClient.get('/api/product/search_by_size', payload);
}

/**
 * @param {MultiSearchRequest} payload
 * @return MultiSearchResponse
 * */
export function multiSearch(payload) {
    return ApiClient.get('/api/product/multi_search', payload);
}

/** @return CatalogMarksResponse */
export function apiCatalogMarks() {
    return ApiClient.get('/api/catalog/marks');
}

/**
 * @param {CatalogModelsRequest} payload
 * @return CatalogModelsResponse
 * */
export function apiCatalogModels(payload) {
    return ApiClient.get('/api/catalog/models', payload);
}

export function apiRepeatOrder(payload) {
    return ApiClient.post('/api/cart/repeat_order', payload);
}
