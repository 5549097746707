var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", { staticClass: "ProductSchemaDetail" }, [
    _c(
      "a",
      {
        staticClass: "ProductSchemaDetail__pictureLink",
        attrs: { href: _vm.url },
      },
      [
        _c(
          "picture",
          {
            staticClass: "ProductSchemaDetail__picture",
            class: {
              "ProductSchemaDetail__picture--withPlaceholder": !_vm.image,
            },
          },
          [
            _vm.image
              ? _c("img", {
                  staticClass: "ProductSchemaDetail__image",
                  attrs: {
                    srcset: `${_vm.image1x}, ${_vm.image2x} 2x`,
                    src: _vm.image1x,
                    alt: _vm.name,
                  },
                })
              : _c("inline-svg", {
                  staticClass: "ProductSchemaDetail__placeholder",
                  attrs: { src: "/assets/images/image-placeholder.svg" },
                }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "ProductSchemaDetail__bodyWrapper" }, [
      _c(
        "a",
        {
          staticClass: "ProductSchemaDetail__nameLink",
          attrs: { href: _vm.url },
        },
        [
          _c("span", {
            staticClass: "ProductSchemaDetail__vendorCode",
            domProps: { innerHTML: _vm._s(_vm.vendorCode) },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "ProductSchemaDetail__name",
            domProps: { innerHTML: _vm._s(_vm.name) },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.quantityInPackage > 0
        ? _c("span", {
            staticClass: "ProductSchemaDetail__packageUnitsQuantity",
            domProps: {
              innerHTML: _vm._s(
                `В&nbsp;упаковке: ${_vm.quantityInPackage}&nbsp;шт.`
              ),
            },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ProductSchemaDetail__priceBlock" }, [
      _c("div", { staticClass: "ProductSchemaDetail__statusBlock" }, [
        _vm.isUserWholesaler
          ? _c("span", { staticClass: "ProductSchemaDetail__priceWholesale" }, [
              _vm.quantityInPackage > 1
                ? _c("span", { staticClass: "ProductSchemaDetail__price" }, [
                    _c("span", [
                      _c("span", {
                        staticClass: "ProductSchemaDetail__price--value",
                        domProps: {
                          innerHTML: _vm._s(
                            `${_vm.formatRoubles(
                              _vm.wholesalePrice.perPackage
                            )}`
                          ),
                        },
                      }),
                      _vm._v(
                        " / " +
                          _vm._s(_vm.quantityInPackage) +
                          " шт.\n                        "
                      ),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            `(${_vm.formatRoubles(
                              _vm.wholesalePrice.perUnit
                            )} / шт.)`
                          ),
                        },
                      }),
                    ]),
                  ])
                : _c("span", { staticClass: "ProductSchemaDetail__price" }, [
                    _c("span", [
                      _c("span", {
                        staticClass: "ProductSchemaDetail__price--value",
                        domProps: {
                          innerHTML: _vm._s(
                            `${_vm.formatRoubles(_vm.wholesalePrice.perUnit)}`
                          ),
                        },
                      }),
                      _vm._v(" / шт.\n                    "),
                    ]),
                  ]),
            ])
          : _c("span", { staticClass: "ProductSchemaDetail__price" }, [
              _c("span", {
                staticClass: "ProductSchemaDetail__price--value",
                domProps: {
                  innerHTML: _vm._s(
                    `${_vm.formatRoubles(_vm.priceRetailPerPackage)}`
                  ),
                },
              }),
              _vm._v(" /упак.\n            "),
            ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ProductSchemaDetail__status" },
          [
            _c("product-status", {
              attrs: {
                quantity: _vm.quantity,
                "quantity-in-package": _vm.quantityInPackage,
                stocks: _vm.stocks,
                "is-user-wholesaler": _vm.isUserWholesaler,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ProductSchemaDetail__controls" }, [
        _c(
          "div",
          { staticClass: "ProductSchemaDetail__cartButton" },
          [
            _c("add-to-cart", {
              attrs: {
                "product-id": _vm.productId,
                quantity: _vm.quantity,
                mode: "catalog",
                "quantity-in-package": _vm.quantityInPackage,
                "is-user-wholesaler": _vm.isUserWholesaler,
                "is-loading": _vm.isLoading,
                disabled: !_vm.possibleToOrder,
              },
              on: { "change-quantity": _vm.changeCartProductQuantity },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ProductSchemaDetail__favoriteButton" }, [
          _c(
            "button",
            {
              staticClass: "Button Button--favorite",
              class: {
                liked: _vm.isFavorite(_vm.productId),
                "Button--noEvents": _vm.isFavoriteLoading(_vm.productId),
              },
              attrs: { type: "button", title: "Добавить в избранное" },
              on: {
                click: function ($event) {
                  return _vm.toggleFavorite(_vm.productId)
                },
              },
            },
            [
              _c(
                "span",
                { staticClass: "Button__icon" },
                [
                  _c("inline-svg", {
                    attrs: { src: "/assets/icons/star.svg" },
                  }),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }