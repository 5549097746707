<template>
    <p
        class="ProductStatus"
        :class="{
            'ProductStatus--available': productStock.status.id === 'available',
            'ProductStatus--onOrder': productStock.status.id === 'on_order',
            'ProductStatus--unavailable':
                productStock.status.id === 'unavailable',
        }"
    >
        <span>{{ productStock.status.name }}</span><span v-if="productStock.delivery_time_in_days > 0">,&nbsp;в&nbsp;течение {{ productStock.delivery_time_in_days }} {{ productStock.day_format }}</span>
    </p>
</template>

<script>
import { reduceStocks } from '@scripts/helpers';

export default {
    props: {
        stocks: Array,
        quantity: {
            type: Number,
            default: 0,
        },
        quantityInPackage: {
            type: Number,
            required: true,
        },
        isUserWholesaler: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            mappedStocks: [],
        };
    },
    beforeMount() {
        // Mapped stocks used for correct status check
        // [{ amount_in_packages: 5 }, { amount_in_packages: 7}] => [{ amount_in_packages: 5 }, { amount_in_packages: 12 }]
        // So if we have 9 packages, we use second status (because quantity > 5, but less than 12)
        this.mappedStocks = reduceStocks(this.stocks);
    },
    computed: {
        // Last status always be `not_available` or smth similar
        // ask @chst_feip
        productStock() {
            let quantity = this.quantity;
            if (this.isUserWholesaler) {
                quantity /= this.quantityInPackage;
            }

            const productStock = this.mappedStocks.find((stock) => quantity <= stock.amount_in_packages)
                ?? this.mappedStocks[this.mappedStocks.length - 1];

            const dayFormat = this.pluralize(productStock.delivery_time_in_days, ['дня', 'дней', 'дней']);

            return ({
                ...productStock,
                day_format: dayFormat,
            });
        },
    },
};
</script>
