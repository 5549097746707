<template>
    <article
        class="ProductCompactCard ProductCompactCard--modeSlider"
    >
        <span class="ProductCompactCard__head">
            <picture
                class="ProductCompactCard__picture"
                :class="{ 'ProductCompactCard__picture--withPlaceholder': !image }"
            >
                <img
                    v-if="image"
                    class="ProductCompactCard__image"
                    :srcset="`${image1x}, ${image2x} 2x`"
                    :src="image1x"
                    :alt="name"
                />
                <inline-svg
                    v-else
                    src="/assets/images/image-placeholder.svg"
                    class="ProductCompactCard__placeholder"
                ></inline-svg>
            </picture>

            <span
                class="ProductCompactCard__title"
                v-if="name"
                v-html="name"
            ></span>

            <p
                class="ProductCompactCard__description"
                v-if="description"
                v-html="description"
            ></p>
        </span>

        <div class="ProductCompactCard__footer">
            <span
                class="ProductCompactCard__price"
                v-if="priceRetailPerPackage"
                v-html="formatRoubles(priceRetailPerPackage)"
            ></span>

            <div class="ProductCompactCard__availability">
                <product-status
                    :quantity="quantity"
                    :quantity-in-package="quantityInPackage"
                    :stocks="stocks"
                    :is-user-wholesaler="isUserWholesaler"
                ></product-status>
            </div>
        </div>

        <div class='ProductCompactCard__controls'>
            <div class="ProductCompactCard__addToCart">
                <add-to-cart
                    :product-id="productId"
                    :quantity="quantity"
                    :mode="'catalog'"
                    :quantity-in-package="quantityInPackage"
                    :is-user-wholesaler="isUserWholesaler"
                    :is-loading="isLoading"
                    @change-quantity="changeCartProductQuantity"
                    :disabled='!possibleToOrder'
                ></add-to-cart>
            </div>

            <button
                class="Button Button--favorite ProductCompactCard__favorite"
                :class="{
                        'liked': isFavorite(productId),
                        'Button--noEvents': isFavoriteLoading(productId),
                    }"
                type="button"
                title="Добавить в избранное"
                @click="toggleFavorite(productId)"
            >
                <span class="Button__icon">
                    <inline-svg src="/assets/icons/star.svg"></inline-svg>
                </span>
            </button>
        </div>
    </article>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import { getImageSize } from '@scripts/helpers';
import { VUEX_ACTIONS, VUEX_GETTERS } from '@scripts/constants';
import { toggleFavorites } from '@scripts/mixins/toggle-favorites';
import InlineSvg from 'vue-inline-svg';
import AddToCart from '../add-to-cart/add-to-cart.vue';
import ProductStatus from '../product-status/product-status.vue';

const PriceDisplayTypesDictionary = {
    wholesalePrice: 'purchase_price',
    retailPrice: 'recommended_retail_price',
    noPrice: 'no_price',
}

export default {
    props: {
        productId: {
            type: Number,
            required: true,
        },
        isProductFavorite: {
            type: Boolean,
        },
        name: {
            type: String,
            required: true,
        },
        description: {
            type: String,
        },
        stocks: {
            type: Array,
        },
        image: {
            type: Object,
        },
        priceRetailPerPackage: {
            type: Number,
            required: true,
        },
        priceRetailPerUnit: {
            type: Number,
        },
        priceWholesalePerPackage: {
            type: Number,
        },
        priceWholesalePerUnit: {
            type: Number,
        },
        quantityInPackage: {
            type: Number,
            required: true,
        },
        quantity: {
            type: Number,
            required: true,
        },
        possibleToOrder: {
            type: Boolean,
            require: true,
        },
        isLoading: {
            type: Boolean,
        },
    },
    components: {
        InlineSvg,
        AddToCart,
        ProductStatus,
    },
    mixins: [toggleFavorites()],
    data() {
        return {
            wholesalePrice: null,
        };
    },
    beforeMount() {
        if (this.isProductFavorite) {
            this.favoritesMap = {
                [`id${this.productId}`] : 'liked',
            };
        }
    },
    computed: {
        ...mapGetters([VUEX_GETTERS.CART_PRODUCT_QUANTITY, VUEX_GETTERS.CART_PRODUCT_LOADING_STATUS, VUEX_GETTERS.IS_USER_WHOLESALER]),

        image1x() {
            return getImageSize(this.image.sizes, this.imageSize);
        },

        image2x() {
            return getImageSize(this.image.sizes, this.imageSize * 2);
        },
    },
    methods: {
        ...mapActions([VUEX_ACTIONS.CHANGE_CART_PRODUCT_QUANTITY]),
    }
};
</script>
