import { PhoneMask, EmailMask, InnMask } from '@library/scripts/masks';
import { ValidationError } from '@library/forms/validation';

export const GLOBAL = {
    APP_ENTRY_POINT: window['tcho-web'] || {},
    RECAPTCHA_SITE_KEY: 'reCaptchaSiteKey',
    DEFAULT_BINDING_PREFIX: 'binding',
};

export const GLOBAL_EVENTS = {
    OPEN_VIN_RESULT: 'bus:open_vin_result',
    OPEN_EDIT_ADDRESS_MODAL: 'bus:open_edit_address_modal',
};

export const DOCUMENT_EVENTS = {
    READY: 'DOMContentLoaded',
    MAP_CONFIG_SET: 'map-config-set',
    MAP_INITIALIZED: 'ymap-initialized',
    LOAD: 'load',
    SCROLL: 'scroll',
};

export const MODALS = {
    LOGIN: '#login-modal',
    LOGOUT_CONFIRM: '#logout-confirm-modal',
    REGISTRATION: '#registration-modal',
    RESET_PASSWORD_REQUEST: '#reset-password-request-modal',
    RESET_PASSWORD_COMPLETE: '#reset-password-complete-modal',
    USER_ADDRESS: '#user-address-modal',
    FEEDBACK: '#feedback-modal',
    CITY_SELECT: '#city-select-modal',
    CLAIM: '#claim-modal',
    VIN_RESULT: '#vin-result-modal',
    VIN_QUERY: '#vin-query-modal',
    DETAILS_REGISTRATION: '#details-registration-modal',
    CLEAR_CART_CONFIRM: '#clear-cart-confirm-modal',
    PRODUCT_SEARCH_BY_SIZE: '#product-search-by-size',
};

export const VUE_ELEMENTS = {
    HEADER: '[data-vue-header]',
    SEARCH_DETAILS: '[data-vue-search-details]',
    CONTACTS_PAGE: '[data-vue-contacts-page]',
    WHOLESALER_FORM: '[data-vue-wholesaler]',
    FEEDBACK_FORM: '[data-vue-feedback-form]',
    VIN_RESULT_MODAL: '[data-vin-result-modal]',
    VIN_FORM: '[data-vue-vin-form]',
    LOGIN_FORM: '[data-vue-login-form]',
    REGISTRATION_FORM: '[data-vue-registration-form]',
    RESET_PASSWORD_REQUEST_FORM: '[data-vue-reset-password-request-form]',
    RESET_PASSWORD_COMPLETE_FORM: '[data-vue-reset-password-complete-form]',
    DETAILS_REGISTRATION_FORM: '[data-vue-details-registration-form]',
    COMMENT_FORM: '[data-vue-comment-form]',
    INFO_LIST: '[data-vue-info-list]',
    COMPATIBILITY_INFO_TABS: '[data-vue-compatibility-info-tabs]',
    USER_INFO_TABS: '[data-vue-user-info-tabs]',
    CATALOG_CARS: '[data-vue-catalog-cars]',
    CATALOG_TITLE: '[data-vue-catalog-title]',
    CATALOG_VIN_INFO: '[data-vue-catalog-vin-info]',
    PRODUCT_CARD: '[data-vue-product-card]',
    USER_INFO_FORM: '[data-vue-user-info-form]',
    USER_PASSWORD_FORM: '[data-vue-user-password-form]',
    USER_ADDRESS_FORM: '[data-vue-user-address-form]',
    USER_ADDRESS_SUGGESTION_FORM: '[data-vue-user-address-suggestion-form]',
    ADDRESSES_LIST: '[data-vue-addresses-list]',
    PRODUCTS_LIST: '[data-vue-products-list]',
    NEIGHBOURS_LIST: '[data-vue-neighbours-list]',
    GALLERY_SWITCHER: '[data-vue-gallery-switcher]',
    MULTI_SELECT: '[data-vue-multi-select]',
    CHECKOUT_PAGE: '[data-vue-checkout-page]',
    CART_PAGE: '[data-vue-cart-page]',
    USER_SETTINGS_FORM: '[data-vue-user-settings-form]',
    // if you will need to add physic user settings - please rename this to LEGAL_USER_SETTINGS_FORM...
    LOGOUT_CONFIRM: '[data-vue-logout-confirm]',
    CITY_SELECT_FORM: '[data-vue-city-select-form]',
    PRODUCT_PAGE: '[data-vue-product-page]',
    REGISTERED_DETAILS_LIST: '[data-vue-registered-details-list]',
    CLAIM_FORM: '[data-vue-claim-form]',
    FAVORITES_PAGE: '[data-vue-favorites-page]',
    CLEAR_CART_CONFIRM: '[data-vue-clear-cart-confirm]',
    REPEAT_ORDER: '[data-vue-repeat-order]',
    ARTICLE_IFRAME: '[data-vue-article-video]',

    SERVICE_STATION_FORM: '[data-vue-service-station]',
    CART_SHARED_PAGE: '[data-vue-cart-shared-page]',
    WHEEL_OF_FORTUNE: '[data-vue-wheel-of-fortune]',
    INDUSTRIAL_SUPPLY: '[data-vue-industrial-supply]',
    PRODUCT_SELECTION_BY_SIZE: '[data-vue-product-selection-by-size]',
};

export const VUEX_GETTERS = {
    CART_QUANTITY_MAP: 'cartQuantityMap',
    CART_PRODUCT_QUANTITY: 'cartProductQuantity',
    CART_PRODUCT_ITEM_ID: 'cartProductItemId',
    CART_TOTAL_ITEMS_QUANTITY: 'cartTotalItemsQuantity',

    CART_PRODUCT_FAKE_QUANTITY: 'cartProductFakeQuantity',
    CART_PRODUCT_LOADING_STATUS: 'cartProductLoadingStatus',
    CART_PRODUCT_WAITING_STATUS: 'cartProductWaitingStatus',

    IS_USER_WHOLESALER: 'isUserWholesaler',
    IS_USER_LOGGED_IN: 'isUserLoggedIn',

    IS_CATALOG_FILTERS_SELECTED: 'isCatalogFiltersSelected',
    SELECTED_CAR: 'selectedCar',
};

export const VUEX_MUTATIONS = {
    SET_USER: 'setUser',
    SET_USER_PHONE: 'setUserPhone',
    SET_USER_NAME: 'setUserName',

    SET_CART: 'setCart',

    SET_CART_PRODUCT_FAKE_QUANTITY: 'setCartProductFakeQuantity',
    SET_CART_PRODUCT_LOADING_STATUS: 'setCartProductLoadingStatus',
    SET_CART_PRODUCT_WAITING_STATUS: 'setCartProductWaitingStatus',

    SET_SELECTED_CITY: 'setSelectedCity',
    SET_IS_CITY_SELECTED: 'setIsCitySelected',
    SET_CITIES_WITH_WAREHOUSES: 'setCitiesWithWarehouses',

    SET_CATALOG_FILTERS_BRAND: 'setCatalogFiltersBrand',
    SET_CATALOG_FILTERS_MODEL: 'setCatalogFiltersModel',
};

export const VUEX_ACTIONS = {
    MODIFY_CART: 'modifyCart',
    ADD_TO_CART: 'addToCart',
    ADD_TO_CART_MULTIPLY: 'addToCartMultiply',
    SET_CART_ITEM_QUANTITY: 'setCartItemQuantity',
    REMOVE_CART_ITEM: 'removeCartItem',
    CLEAR_CART: 'clearCart',
    REFRESH_CART: 'refreshCart',
    REPEAT_ORDER: 'repeatOrder',

    SYNC_CART_PRODUCT_QUANTITY: 'syncCartProductQuantity',
    CHANGE_CART_PRODUCT_QUANTITY: 'changeCartProductQuantity',
};

export const MESSAGES = {
    SUCCESS_FORM_SUBMITTING: 'Заявка успешно отправлена!',
    SUCCESS_PHONE_CHANGING: 'Телефон успешно изменен',
    ERROR_CART_MODIFYING: 'Ошибка в работе с корзиной. Попробуйте перезагрузить страницу.',
    SUCCESS_USER_NAME_CHANGING: 'Имя успешно изменено',
    SUCCESS_USER_EMAIL_CHANGING: 'На указанный адрес электронной почты было отправлено письмо для подтверждения',
    FAVORITE_TOGGLE_ERROR: 'Ошибка при добавлении товара в избранное. Попробуйте перезагрузить страницу.',
    FAVORITE_SUCCESS_ADD: 'Товар добавлен в избранное',
    FAVORITE_SUCCESS_REMOVE: 'Товар удален из избранного',
    FILES_UPLOAD_ERROR: 'Не удалось прикрепить файлы',
    BILL_LOAD_ERROR: 'Не удалось скачать счет',
    CITIES_LOAD_ERROR: 'Не удалось загрузить города',
    COUNTRIES_LOAD_ERROR: 'Не удалось загрузить страны',
    STREETS_LOAD_ERROR: 'Не удалось загрузить улицы',
    REMOVE_ADDRESS_ERROR: 'Не удалось удалить адрес',
    REMOVE_ADDRESS_SUCCESS: 'Адрес успешно удален',
    FILE_UPLOAD_SIZE_ERROR: 'Размер файла превышает 7 Мб',
    SUCCESS_COMMENT_SUBMIT: 'Ваш комментарий будет показан после одобрения модератором',
    SUCCESS_REGISTRATION: 'Вы успешно зарегистрированы! На указанный адрес электронной почты отправлено сообщение для подтверждения регистрации',
    SUCCESS_REGISTRATION_CONFIRMED: 'Регистрация успешно подтверждена',
    LOGOUT_ERROR: 'Кажется, что-то пошло не так. Попробуйте перезагрузить страницу.',
    SUCCESS_CITY_CHANGE: 'Город успешно изменен',
    PRODUCT_QUANTITY_ROUNDED_TITLE: 'Значение округлено',
    PRODUCT_QUANTITY_ROUNDED_TEXT: 'Количество товара должно быть кратно числу штук в упаковке',
    SUCCESS_SETTINGS_SAVED: 'Настройки сохранены',
    PRODUCTS_ADDED_SUCCESS: 'Товар добавлен в корзину',
    REGISTER_DETAIL_SERIAL_NUMBERS_ERROR: 'Регистрация детали отклонена. Проверьте введенные серийные номера',
    SUCCESS_RESET_PASSWORD: 'Вы успешно сменили пароль',
    CHECK_VIN_OR_FRAME_ERROR: 'Не удалось найти модель автомобиля. Проверьте введенный номер VIN / кузова',
    SUCCESS_REDIRECT_VIN_CHECK: 'Проверяем применяемость товаров по VIN‑номеру',
    ERROR_FIND_APPLICABLE_DETAILS: 'Не удалось найти схемы',
};

export const VALIDATION_MESSAGES = {
    TOO_SHORT_VALUE: 'Слишком мало символов',
    TOO_LONG_VALUE: 'Слишком много символов',
    WRONG_VALUE_FORMAT: 'Неправильный формат',
    REQUIRED_ERROR: 'Заполните это поле',
    DEFAULT_ERROR: 'Ошибка',
    SERVER_ERROR: 'Ошибка. Кажется, что-то пошло не так. Попробуйте перезагрузить страницу.',
    AGREEMENT: 'Вы должны принять эти правила',
    RECAPTCHA_ERROR: 'Ошибка проверки токена reCAPTCHA',
    TOO_WEAK_PASSWORD: 'Слишком ненадежный пароль',
    CONFIRM_PASSWORD_NOT_MATCH: 'Введенные пароли не совпадают',
};

export const VALIDATION_RULES = {
    EMAIL: {
        regexp: EmailMask.formattedRule,
    },
    PHONE: {
        regexp: PhoneMask.formattedRule,
    },
    PHONE_FREE: {
        regexp: /^\+\d \d{3} \d\d{0,2} ?\d{0,2} ?\d{0,2} ?\d{0,2} ?\d{0,2}$/,
    },
    INN: {
        regexp: InnMask.formattedRule,
    },
    OGRN: {
        regexp: /^\d{3} \d{3} \d{3} \d{4}$/,
    },
    SMALL_TEXT: {
        minLength: 1,
        maxLength: 100,
    },
    SMALL200_TEXT: {
        minLength: 1,
        maxLength: 200,
    },
    MEDIUM_TEXT: {
        minLength: 1,
        maxLength: 500,
    },
    LARGE_TEXT: {
        minLength: 3,
        maxLength: 2000,
    },
    PERCENT: {
        min: 0,
        max: 100,
    },
    PASSWORD: {
        validator(value) {
            const result = this.isFieldValid(value, {
                regexp: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
            });
            if (result instanceof ValidationError) {
                return new ValidationError(this.validationMessages.TOO_WEAK_PASSWORD);
            }
            return true;
        },
    },
    CONFIRM_PASSWORD: {
        required: true,
        validator(value) {
            if (value !== this.formData.password) {
                return new ValidationError(this.validationMessages.CONFIRM_PASSWORD_NOT_MATCH);
            }
            return true;
        },
    },
};

export const USER_TYPES = {
    PHYSIC: 'individual',
    LEGAL: 'legal_entity',
    WHOLESALER: 'wholesaler',
};

export const PRODUCT_STATUSES = {
    AVAILABLE: 'available',
    FOR_ORDER: 'forOrder',
    UNAVAILABLE: 'unavailable',
    NOT_MANUFACTURING: 'notManufacturing',
};

export const FAVORITE_STATES = {
    LOADING: 'loading',
    NOT_LIKED: 'notLiked',
    LIKED: 'liked',
};

export const SEARCH_TABS = {
    BRAND_MODEL: 'brandModel',
    OEM: 'oem',
    VIN: 'vin',
};
