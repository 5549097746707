<template>
    <div
        class="MiniCartItem"
        :class="{
            'MiniCartItem--removing': isRemoving,
            'MiniCartItem--lastChild': isLastChild,
        }"
    >
        <a :href="url" v-if="!isPresentInRossko">
            <picture
                class="MiniCartItem__picture"
                :class="{ 'MiniCartItem__picture--withPlaceholder': !image }"
            >
                <img
                    v-if="image"
                    class="MiniCartItem__image"
                    :srcset="`${image1x}, ${image2x} 2x`"
                    :src="image1x"
                    :alt="name"
                />
                <inline-svg
                    v-else
                    src="/assets/images/image-placeholder.svg"
                    class="MiniCartItem__placeholder"
                ></inline-svg>
            </picture>
        </a>

        <span v-else>
            <picture
                class="MiniCartItem__picture"
                :class="{ 'MiniCartItem__picture--withPlaceholder': !image }"
            >
                <img
                    v-if="image"
                    class="MiniCartItem__image"
                    :srcset="`${image1x}, ${image2x} 2x`"
                    :src="image1x"
                    :alt="name"
                />
                <inline-svg
                    v-else
                    src="/assets/images/image-placeholder.svg"
                    class="MiniCartItem__placeholder"
                ></inline-svg>
            </picture>
        </span>

        <div class="MiniCartItem__info">
            <div class="MiniCartItem__nameWrapper">
                <a :href="url" class="MiniCartItem__nameLink" v-if="!isPresentInRossko">
                    <span v-html="name" class="MiniCartItem__name"></span>
                </a>
                <span class="MiniCartItem__nameSpan" v-else>
                    <span v-html="name" class="MiniCartItem__name"></span>
                </span>
            </div>

            <div class="MiniCartItem__footer">
                <div class="MiniCartItem__priceBlock">
                    <div class="MiniCartItem__priceValues">
                        <span
                            class="MiniCartItem__price MiniCartItem__price--total"
                            v-html="formatRoubles(totalPrice)"
                        ></span>

                        <span
                            class="MiniCartItem__price MiniCartItem__price--perUnit"
                            v-if="isUserWholesaler"
                            v-show="isPricePerItemShown"
                            v-html="`(${formatRoubles(pricePerItem)} / шт.)`"
                            :key="'price-per-item'"
                        ></span>

                        <span
                            class="MiniCartItem__price MiniCartItem__price--perUnit"
                            v-else
                            v-show="isPricePerPackageShown"
                            v-html="
                                `(${formatRoubles(pricePerPackage)} / упак.)`
                            "
                            :key="'price-per-package'"
                        ></span>
                    </div>
                </div>

                <div class="MiniCartItem__aboutBlock">
                    <span
                        class="MiniCartItem__packageUnitsQuantity"
                        v-html="
                            `В&nbsp;упаковке: ${packageItemsCount}&nbsp;шт.`
                        "
                    ></span>

                    <div class="MiniCartItem__stocks">
                        <product-status
                            :quantity="quantity"
                            :quantity-in-package="packageItemsCount"
                            :stocks="stocks"
                            :is-user-wholesaler="isUserWholesaler"
                        ></product-status>
                    </div>
                </div>

                <div class="MiniCartItem__counter">
                    <add-to-cart
                        :product-id="productId"
                        :quantity="quantity"
                        :quantity-in-package="packageItemsCount"
                        :is-user-wholesaler="isUserWholesaler"
                        :mode="'cart'"
                        :is-loading="isLoading"
                        :always-show-counter="true"
                        @change-quantity="changeQuantity"
                    ></add-to-cart>
                </div>
            </div>

            <button
                type="button"
                class="Button Button--remove MiniCartItem__removeButton"
                @click="removeItem"
            >
                <span class="Button__icon">
                    <inline-svg src="/assets/icons/close.svg"></inline-svg>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import CartItem from '../cart-item/cart-item.vue';

export default {
    extends: CartItem,
    props: {
        isLastChild: {
            type: Boolean,
            default: false,
        },
        stocks: {
            type: Array,
        },
        isPresentInRossko: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        isPricePerItemShown() {
            return this.quantity > 1 && this.pricePerItem > 0;
        },

        isPricePerPackageShown() {
            return this.quantity > 1 && this.pricePerPackage > 0;
        },
    },
};
</script>
