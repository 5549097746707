<template>
        <article
            class="ProductSchemaDetail"
        >
            <a :href="url" class="ProductSchemaDetail__pictureLink">
                <picture
                    class="ProductSchemaDetail__picture"
                    :class="{ 'ProductSchemaDetail__picture--withPlaceholder': !image }"
                >
                    <img
                        v-if="image"
                        class="ProductSchemaDetail__image"
                        :srcset="`${image1x}, ${image2x} 2x`"
                        :src="image1x"
                        :alt="name"
                    />
                    <inline-svg
                        v-else
                        src="/assets/images/image-placeholder.svg"
                        class="ProductSchemaDetail__placeholder"
                    ></inline-svg>
                </picture>
            </a>

            <div class="ProductSchemaDetail__bodyWrapper">
                <a :href="url" class="ProductSchemaDetail__nameLink">
                    <span
                        v-html="vendorCode"
                        class="ProductSchemaDetail__vendorCode"
                    ></span>
                    <span v-html="name" class="ProductSchemaDetail__name"></span>
                </a>

                <span
                    class="ProductSchemaDetail__packageUnitsQuantity"
                    v-if="quantityInPackage > 0"
                    v-html="`В&nbsp;упаковке: ${quantityInPackage}&nbsp;шт.`"
                ></span>
            </div>

            <div class="ProductSchemaDetail__priceBlock">
                <div class="ProductSchemaDetail__statusBlock">
                    <span
                        class="ProductSchemaDetail__priceWholesale"
                        v-if='isUserWholesaler'
                    >
                        <span
                            class="ProductSchemaDetail__price"
                            v-if='quantityInPackage > 1'
                        >
                            <span>
                                <span
                                    class='ProductSchemaDetail__price--value'
                                    v-html="`${formatRoubles(wholesalePrice.perPackage)}`"
                                ></span>&nbsp;/&nbsp;{{ quantityInPackage }}&nbsp;шт.
                                <span v-html="`(${formatRoubles(wholesalePrice.perUnit)} / шт.)`"></span>
                            </span>
                        </span>
                        <span
                            class="ProductSchemaDetail__price"
                            v-else
                        >
                            <span>
                                <span
                                    class='ProductSchemaDetail__price--value'
                                    v-html="`${formatRoubles(wholesalePrice.perUnit)}`"
                                ></span>&nbsp;/&nbsp;шт.
                            </span>
                        </span>
                    </span>

                    <span
                        class="ProductSchemaDetail__price"
                        v-else
                    >
                        <span
                          class="ProductSchemaDetail__price--value"
                          v-html="`${formatRoubles(priceRetailPerPackage)}`"
                        ></span>&nbsp;/упак.
                    </span>

                    <div class="ProductSchemaDetail__status">
                        <product-status
                            :quantity="quantity"
                            :quantity-in-package="quantityInPackage"
                            :stocks="stocks"
                            :is-user-wholesaler="isUserWholesaler"
                        ></product-status>
                    </div>
                </div>

                <div class='ProductSchemaDetail__controls'>
                    <div class="ProductSchemaDetail__cartButton">
                        <add-to-cart
                            :product-id="productId"
                            :quantity="quantity"
                            :mode="'catalog'"
                            :quantity-in-package="quantityInPackage"
                            :is-user-wholesaler="isUserWholesaler"
                            :is-loading="isLoading"
                            @change-quantity="changeCartProductQuantity"
                            :disabled='!possibleToOrder'
                        ></add-to-cart>
                    </div>

                    <div class='ProductSchemaDetail__favoriteButton'>
                        <button
                            class="Button Button--favorite"
                            :class="{
                                'liked': isFavorite(productId),
                                'Button--noEvents': isFavoriteLoading(productId),
                            }"
                            type="button"
                            title="Добавить в избранное"
                            @click="toggleFavorite(productId)"
                        >
                            <span class="Button__icon">
                                <inline-svg src="/assets/icons/star.svg"></inline-svg>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </article>
</template>

<script>
import { mapActions } from 'vuex';
import { getFromBinding, getImageSize } from '@scripts/helpers';
import { VUEX_ACTIONS } from '@scripts/constants';
import { toggleFavorites } from '@scripts/mixins/toggle-favorites';
import InlineSvg from 'vue-inline-svg';
import AddToCart from '../add-to-cart/add-to-cart.vue';
import ProductStatus from '../product-status/product-status.vue';

const PriceDisplayTypesDictionary = {
    wholesalePrice: 'purchase_price',
    retailPrice: 'recommended_retail_price',
    noPrice: 'no_price',
}

export default {
    props: {
        productId: {
            type: Number,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        vendorCode: {
            type: String,
        },
        url: {
            type: String,
            required: true,
        },
        stocks: {
            type: Array,
        },
        image: {
            type: Object,
        },
        priceRetailPerPackage: {
            type: Number,
            required: true,
        },
        priceRetailPerUnit: {
            type: Number,
        },
        priceWholesalePerPackage: {
            type: Number,
        },
        priceWholesalePerUnit: {
            type: Number,
        },
        quantityInPackage: {
            type: Number,
            required: true,
        },
        quantity: {
            type: Number,
            required: true,
        },
        possibleToOrder: {
            type: Boolean,
            require: true,
        },
        isUserWholesaler: {
            type: Boolean,
            required: true,
        },
        priceDisplayType: {
            type: String,
            require: true,
        },
        isLoading: {
            type: Boolean,
        },
    },
    components: {
        InlineSvg,
        AddToCart,
        ProductStatus,
    },
    mixins: [toggleFavorites()],
    data() {
        return {
            wholesalePrice: null,
        };
    },
    beforeMount() {
        this.favoritesMap = getFromBinding('productsData.favoritesMap');

        if (this.isUserWholesaler) {
            if (this.priceDisplayType !== PriceDisplayTypesDictionary.noPrice) {
                this.wholesalePrice = {
                    perUnit: this.priceWholesalePerUnit,
                    perPackage: this.priceWholesalePerPackage,
                }
            }

            if (this.priceDisplayType === PriceDisplayTypesDictionary.retailPrice) {
                this.wholesalePrice = {
                    perUnit: this.priceRetailPerUnit,
                    perPackage: this.priceRetailPerPackage,
                }
            }
        }
    },
    computed: {
        image1x() {
            return getImageSize(this.image.sizes, this.imageSize);
        },

        image2x() {
            return getImageSize(this.image.sizes, this.imageSize * 2);
        },
    },
    methods: {
        ...mapActions([VUEX_ACTIONS.CHANGE_CART_PRODUCT_QUANTITY]),
    }
};
</script>
